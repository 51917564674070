import { DateTime } from 'luxon';
import type { LocationDataPoint } from '../contracts/data-point.js';
import { addStats } from './add-stats.js';
import type { Leg, LegWithStats, MissedVisit, VisitTime } from './types.js';
import { isVisit } from './types.js';

interface TempLeg {
  from?: VisitTime | MissedVisit;
  to?: VisitTime | MissedVisit;
}

const getDiffSeconds = (from: Date, to: Date): number =>
  DateTime.fromJSDate(to).diff(DateTime.fromJSDate(from), 'seconds').seconds;

export const toLegs = (
  visitsOrMisses: (VisitTime | MissedVisit)[],
  locations: LocationDataPoint[],
): LegWithStats[] => {
  if (!visitsOrMisses.length) return [];

  const fromTo = visitsOrMisses
    .slice(1)
    .reduce<TempLeg[]>(
      (acc, visit) => [...acc, { from: acc[acc.length - 1].to, to: visit }],
      [{ to: visitsOrMisses[0] }],
    );

  if (!locations.length) {
    return fromTo.map(
      ({ from, to }) =>
        ({
          from,
          to,
          locations: undefined,
          seconds: undefined,
          stats: {},
        }) as LegWithStats,
    );
  }

  let nextLocation = 0;

  const legs = fromTo.map<Leg>(({ from, to }) => {
    // first leg
    if (!from && to) {
      if (isVisit(to)) {
        // points until visit time
        const toWhen = to.nearest;
        const current: LocationDataPoint[] = [];
        for (; nextLocation < locations.length - 1; nextLocation++) {
          const location = locations[nextLocation];
          if (location.when > toWhen) break;
          current.push(location);
          if (location.when === toWhen) break;
        }
        return {
          from,
          to,
          locations: current,
          seconds: current.length
            ? getDiffSeconds(current[0].when, to.nearest)
            : 0,
        } satisfies Leg;
      }
      // no points
      return {
        from,
        to,
        locations: undefined,
        seconds: undefined,
      } satisfies Leg;
    }
    // last leg
    if (from && !to) {
      if (isVisit(from)) {
        // remaining points
        return {
          from,
          to,
          locations: locations.slice(nextLocation),
          seconds: locations.length
            ? getDiffSeconds(from.nearest, locations[locations.length - 1].when)
            : 0,
        } satisfies Leg;
      }
      // no points
      return {
        from,
        to,
        locations: undefined,
        seconds: undefined,
      } satisfies Leg;
    }
    // legs in between
    if (from && to) {
      if (isVisit(from) && isVisit(to)) {
        // points in between
        const toWhen = to.nearest;
        const current: LocationDataPoint[] = [];
        for (; nextLocation < locations.length - 1; nextLocation++) {
          const location = locations[nextLocation];
          if (location.when > toWhen) break;
          current.push(location);
          if (location.when === toWhen) break;
        }
        return {
          from,
          to,
          locations: current,
          seconds: locations.length
            ? getDiffSeconds(from.nearest, to.nearest)
            : 0,
        } satisfies Leg;
      }
      if (isVisit(to)) {
        // throw away points
        const toWhen = to.nearest;
        for (; nextLocation < locations.length - 1; nextLocation++) {
          const location = locations[nextLocation];
          if (location.when >= toWhen) break;
        }
        // no points
        return {
          from,
          to,
          locations: undefined,
          seconds: undefined,
        } satisfies Leg;
      }
      // no points
      return {
        from,
        to,
        locations: undefined,
        seconds: undefined,
      } satisfies Leg;
    }
    throw new Error('There is neither "from" nor "to" defined');
  });
  return legs.map(addStats);
};
