import { helpers, nearestPointOnLine } from '@turf/turf';
import type { CourseControl, ResolvedControl } from '../contracts/control.js';
import type { LocationDataPoint } from '../contracts/data-point.js';
import { defaultControlRadius } from '../util/control.js';
import { getDistance } from '../util/distance.js';
import { controlHasLatLong } from '../util/geo.js';
import type { VisitTime } from './types.js';

interface Near {
  point: LocationDataPoint;
  distance: number;
}

interface Reduction {
  visits: ControlVisit[];
  currentControls: Map<CourseControl, Near[]>;
}

export interface ControlVisit {
  control: CourseControl;
  points: Near[];
  departed: boolean;
}

export const computeVisits = <T extends LocationDataPoint>(
  locations: T[],
  controls: ResolvedControl[],
  defaultRadius = 10,
): ControlVisit[] => {
  // for each point, find all controls within range
  const stage1 = locations.map((point, index) => {
    const prev = index > 0 ? locations[index - 1] : undefined;
    const controlsInRange = controls
      .filter(controlHasLatLong)
      .map<{
        control: ResolvedControl;
        distance: number;
        inRange: boolean;
        syntheticPoint?: LocationDataPoint;
      }>((control) => {
        const radius =
          control.radius ??
          defaultRadius * (control.type === 'finish' ? 1.5 : 1);
        const distance = getDistance(
          [point.long, point.lat],
          [control.long, control.lat],
        );
        const defaultResult = {
          control,
          distance: getDistance(
            [point.long, point.lat],
            [control.long, control.lat],
          ),
          inRange: distance <= radius,
        };
        if (distance <= radius || distance > 100 || !prev) {
          return defaultResult;
        }

        // point was not withing range, but close, maybe it draws line through
        if (
          getDistance([prev.long, prev.lat], [control.long, control.lat]) <=
          radius
        ) {
          // previous point was already counted
          return defaultResult;
        }

        const nearest = nearestPointOnLine(
          helpers.lineString([
            [prev.long, prev.lat],
            [point.long, point.lat],
          ]),
          helpers.point([control.long, control.lat]),
          { units: 'meters' },
        );

        if (
          nearest.properties.dist === undefined ||
          nearest.properties.location === undefined ||
          nearest.properties.dist > radius
        ) {
          return defaultResult;
        }

        const distanceBetweenPoints = getDistance(
          [prev.long, prev.lat],
          [point.long, point.lat],
        );
        const fraction = nearest.properties.location / distanceBetweenPoints;

        return {
          control,
          distance: nearest.properties.dist,
          inRange: nearest.properties.dist <= radius,
          syntheticPoint: {
            when: new Date(
              prev.when.getTime() +
                (point.when.getTime() - prev.when.getTime()) * fraction,
            ),
            lat: nearest.geometry.coordinates[1],
            long: nearest.geometry.coordinates[0],
            // TODO: interpolate extra data
          } satisfies LocationDataPoint,
        };
      })
      .filter(({ inRange }) => inRange);
    return { point, controlsInRange };
  });

  // group points together into control visits
  const stage2 = stage1.reduce<Reduction>(
    (acc, point) => {
      const newControls = point.controlsInRange.reduce<
        Map<CourseControl, Near[]>
      >((innerAcc, { control, distance, syntheticPoint }) => {
        const near: Near = { point: syntheticPoint ?? point.point, distance };
        const current = acc.currentControls.get(control);
        if (current) {
          return new Map(innerAcc).set(control, [...current, near]);
        }
        return new Map(innerAcc).set(control, [near]);
      }, new Map());

      // work out controls that have been departed
      const departedControls = Array.from(newControls.keys()).reduce(
        (innerAcc, id) => {
          innerAcc.delete(id);
          return innerAcc;
        },
        new Set(acc.currentControls.keys()),
      );

      // make sure to include any controls that are still being visited at the end (finish control)
      return {
        visits: [
          ...acc.visits,
          ...Array.from(departedControls).map((control) => ({
            control,
            points: acc.currentControls.get(control) ?? [],
            departed: true,
          })),
        ],
        currentControls: newControls,
      };
    },
    {
      visits: [],
      currentControls: new Map<CourseControl, Near[]>(),
    } as Reduction,
  );

  return [
    ...stage2.visits,
    ...Array.from(stage2.currentControls.keys()).map((control) => ({
      control,
      points: stage2.currentControls.get(control) ?? [],
      departed: false,
    })),
  ];
};

export const computeVisitTimes = <T extends LocationDataPoint>(
  locations: T[],
  controls: ResolvedControl[],
  eventType: string | null | undefined,
  radiusOverride: number | null | undefined,
): VisitTime[] => {
  const defaultRadius = defaultControlRadius(eventType, radiusOverride);
  const visits = computeVisits(locations, controls, defaultRadius);

  return visits.map((visit) => {
    const nearest = visit.points.reduce<Near>(
      (acc, point) => (point.distance < acc.distance ? point : acc),
      visit.points[0],
    );
    return {
      control: visit.control,
      arrived: visit.points[0].point.when,
      nearest: nearest.point.when,
      nearestDistance: nearest.distance,
      departed: visit.departed
        ? visit.points[visit.points.length - 1].point.when
        : undefined,
    };
  });
};
