import type { ResolvedControl } from '../contracts/control.js';
import type { VisitTime } from './types.js';

interface Reduction {
  finishVisit?: VisitTime;
  trailingControVisit: boolean;
  visitedControls: Record<string, boolean>;
  visits: VisitTime[];
}

// polyfill for older browsers
const findLast = <T>(
  arr: T[],
  predicate: (element: T) => boolean,
): T | undefined => {
  for (let i = arr.length - 1; i >= 0; i--) {
    if (predicate(arr[i])) {
      return arr[i];
    }
  }
  return undefined;
};

// polyfill for older browsers
const findLastIndex = <T>(
  arr: T[],
  predicate: (element: T) => boolean,
): number => {
  for (let i = arr.length - 1; i >= 0; i--) {
    if (predicate(arr[i])) {
      return i;
    }
  }
  return -1;
};

export const rogaineMatch = (
  visitTimes: VisitTime[],
  controls: ResolvedControl[],
): VisitTime[] => {
  const firstControlIndex = visitTimes.findIndex(
    (visit) => !['start', 'finish'].includes(visit.control.type ?? 'control'),
  );
  const startVisit = findLast(
    visitTimes.slice(0, firstControlIndex),
    (visit) => visit.control.type === 'start',
  );
  const lastControlIndex = findLastIndex(
    visitTimes,
    (visit) => visit.control.type === 'finish',
  );

  // ignore start visits after the one other control visit
  const visitTimesFiltered = visitTimes
    .slice(firstControlIndex, lastControlIndex + 1)
    .filter(
      (visit, index) =>
        index < 1 ||
        (visit.control.type ??
          controls.find((control) => control._id === visit.control._id)
            ?.type) !== 'start',
    );

  const { finishVisit, visits } = visitTimesFiltered.reduce<Reduction>(
    (acc, visit) => {
      const controlType =
        visit.control.type ??
        controls.find((control) => control._id === visit.control._id)?.type;
      // only record the last finish
      if (controlType === 'finish') {
        // only record finish if there have been control visits since the last finish
        if (!acc.trailingControVisit) return acc;
        return {
          finishVisit: visit,
          trailingControVisit: false,
          visitedControls: acc.visitedControls,
          visits: acc.visits,
        };
      }

      // if control visit is already recorded, then don't record again
      if (acc.visitedControls[visit.control._id]) return acc;

      return {
        finishVisit: acc.finishVisit,
        trailingControVisit: true,
        visitedControls: { ...acc.visitedControls, [visit.control._id]: true },
        visits: [...acc.visits, visit],
      };
    },
    { trailingControVisit: false, visitedControls: {}, visits: [] },
  );

  const results = finishVisit
    ? [
        ...visits.filter((visit) => visit.nearest < finishVisit.nearest),
        finishVisit,
      ]
    : visits;
  if (startVisit) {
    results.unshift(startVisit);
  }
  return results;
};
