import type { ResolvedControl } from '../contracts/control.js';
import type { LocationDataPoint } from '../contracts/data-point.js';

export const isVisit = (value: VisitTime | MissedVisit): value is VisitTime =>
  !!(value as VisitTime).arrived && !!(value as VisitTime).nearest;

export interface VisitTime {
  control: ResolvedControl;
  arrived: Date;
  nearest: Date;
  nearestDistance: number;
  departed?: Date;
}

export interface MissedVisit {
  control: ResolvedControl;
}

export interface LegStats {
  meters?: number | undefined;
  secondsPerMeter?: number | undefined;
  altitude: {
    increase?: number | undefined;
    decrease?: number | undefined;
  };
}

export interface LegTimedStats extends LegStats {
  seconds?: number | undefined;
}

export type Leg =
  | {
      from: undefined;
      locations: LocationDataPoint[];
      seconds: number;
      to: VisitTime;
    }
  | {
      from: undefined;
      locations: undefined;
      seconds: undefined;
      to: MissedVisit;
    }
  | {
      from: VisitTime;
      locations: LocationDataPoint[];
      seconds: number;
      to: VisitTime;
    }
  | {
      from: MissedVisit;
      locations: undefined;
      seconds: undefined;
      to: MissedVisit;
    }
  | {
      from: MissedVisit;
      locations: undefined;
      seconds: undefined;
      to: VisitTime;
    }
  | {
      from: VisitTime;
      locations: undefined;
      seconds: undefined;
      to: MissedVisit;
    }
  | {
      from: VisitTime;
      locations: LocationDataPoint[];
      seconds: number;
      to: undefined;
    }
  | {
      from: MissedVisit;
      locations: undefined;
      seconds: undefined;
      to: undefined;
    };

export type LegWithStats = Leg & {
  stats: LegStats;
};
