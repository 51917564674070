import type { Leg, LegWithStats } from './types.js';

export const addStats = (leg: Leg): LegWithStats => {
  const { firstDist, lastDist, incAlt, decAlt } = leg.locations
    ? leg.locations.reduce<{
        firstDist?: number;
        lastDist?: number;
        prevAlt?: number;
        incAlt?: number;
        decAlt?: number;
      }>(
        (acc, point) => ({
          firstDist: acc.firstDist ?? point.data?.dist,
          lastDist: point.data?.dist ?? acc.lastDist,
          prevAlt: point.data?.alt ?? acc.prevAlt,
          incAlt:
            acc.prevAlt !== undefined &&
            point.data?.alt !== undefined &&
            acc.prevAlt < point.data?.alt
              ? (acc.incAlt ?? 0) + point.data?.alt - acc.prevAlt
              : acc.incAlt,
          decAlt:
            acc.prevAlt !== undefined &&
            point.data?.alt !== undefined &&
            acc.prevAlt > point.data?.alt
              ? (acc.decAlt ?? 0) + acc.prevAlt - point.data?.alt
              : acc.decAlt,
        }),
        {},
      )
    : {
        firstDist: undefined,
        lastDist: undefined,
        incAlt: undefined,
        decAlt: undefined,
      };

  const meters = firstDist && lastDist ? lastDist - firstDist : undefined;
  return {
    ...leg,
    stats: {
      meters,
      secondsPerMeter: meters && leg.seconds ? leg.seconds / meters : undefined,
      altitude: {
        increase: incAlt,
        decrease: decAlt,
      },
    },
  };
};
