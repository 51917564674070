import type { DataPoint, LocationDataPoint } from '../contracts/data-point.js';
import { isLocationDataPoint } from '../contracts/data-point.js';

export const flattenSegments = (segments: DataPoint[][]): DataPoint[] =>
  segments.reduce<DataPoint[]>((acc, segment) => [...acc, ...segment], []);

export const flattenLocationSegments = (
  segments: DataPoint[][],
): LocationDataPoint[] =>
  segments.reduce<LocationDataPoint[]>(
    (acc, segment) => [...acc, ...segment.filter(isLocationDataPoint)],
    [],
  );
